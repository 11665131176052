import uuid from "uuid"
import Vue from "vue";
import Vuex from "vuex";
import permissions from '../common/permissions'
import wwwlDefaults from '../common/wwwlDefaults.json'
 
Vue.use(Vuex);
 
export default new Vuex.Store({
    state: {
        uid: uuid.v4(),
        dicomWebQidoUrl: '',
        groupAetLut: {},
        keycloak: null,
        userProfile: null,
        reportId: '',
        // actionComponent: component in side panel of 'this' window.
        activeComponent: '',
        // activeStudyUid: study for component in side panel of 'this' window.
        activeStudyUid: '',
        // _selectedStudyUid: study opened in 'this' window.
        _selectedStudyUid: '',
        // _selectedStudyUids: studies opened in all viewer or report components/windows (used by worklist window).
        _selectedStudyUids: {},
        // _nextStudyUid: next study for review on worklist sort.
        _nextStudyUid: null,
        // _previousStudyUid: previous study for review based on worklist sort.
        _previousStudyUid: null,
        // list of studies selected on worklist.
        checkedStudies: [],
        patientHistoryDocId: '',
        docServicesUrl: '',
        uploadLimitMb: 1,
        queryLimit: 10000,
        refreshViewer: false,
        locale: (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language,
        mgmtReportItems: [],
        mgmtReportRefreshing: false,
        mgmtReportTS: 0,
        mgmtReportType: 'productivity',
        openStudies: {},
        prevRoute: 'worklist',
        reportPhraseLut: {},
        reportWindowBox: {
            x: window.screen.availWidth / 2,
            y: 0,
            w: window.screen.availWidth / 2,
            h: window.screen.availHeight
        },
        reportWindowsEditing: {},
        reportWindows: {},
        secondaryWorklist: [],
        studyStatusTs: new Date().toISOString(),
        inViewerWindow: false,
        viewerWindowBox: {
            x: window.screen.availWidth / 2,
            y: 0,
            w: window.screen.availWidth / 2,
            h: window.screen.availHeight
        },
        viewerWindowsRoute: {},
        viewerWindows: {},
        webServicesBaseUrl: '',
        worklist: [],
        worklistCount: 0,
        cachedWorklistName: '',
        worklistName: '',
        worklistNames: ['Today'],
        worklistPerPage: 10,
        worklistCurrentPage: 1,
        worklistRefreshFlag: true, // false if error during last refresh
        worklistRefreshInterval: 5, // in minutes
        worklistRefreshing: '',
        worklistRefreshTime: '---', // last time worklist was refreshed
        worklistSettings: {},
        worklistSorted: [],
        worklistSortBy: 'study_date_time',
        worklistSortDesc: true,
        worklistActions: [ 'viewer_lite', 'viewer_full', 'export_study', 'add_objects', 'assign_study', 'patient_history', 'edit_patient', 'view_report' ],
        worklistColumns: [ 'actions', 'patient_name', 'patient_age', 'dob', 'patient_id', 'modality', 'study_desc', 'study_date_time' ],
        worklistColumnWidths: {},
        worklistAltViewers: [],
        worklistViewers: { 
            "dbl_click_route": "viewer",
            "lite_quality": 70,
            "lite_annotation_color": "#85aed3",
            "reports_open_in": "sidePanel",
            "viewers_open_in": "worklistWindow"
        },
        wwwlFavorites: wwwlDefaults.data
    },
    getters: {
        user: (state) => {
            let userProfile = state.userProfile
            if (userProfile != null) {
            if (("firstName" in userProfile) && ("lastName" in userProfile)) {
                return userProfile["firstName"]+" "+userProfile["lastName"]
            }
                return state.userProfile.username
            }
            return "User"
        },
        worklistEntryForStudy: (state) => (studyUid) => {
            var entry = null
            for(var j=0; j < state.worklist.length; j++) {
                if (state.worklist[j].study_uid == studyUid) {
                    entry = state.worklist[j]
                    break
                }
            }
            if (entry == null) {
                // Check if there is a secondary study entry available...
                //
                for(let j=0; j < state.secondaryWorklist.length; j++) {
                    if (state.secondaryWorklist[j].study_uid == studyUid) {
                        entry = state.secondaryWorklist[j]
                        break
                    }
                }
            }
            return entry;
        },
        worklistEntryForSelectedStudyUid: (state) => (windowUid = '') =>  {
            var entry = null
            const selectedStudyUid = state._selectedStudyUids[windowUid]
            for(let j=0; j < state.worklist.length; j++) {
                if (state.worklist[j].study_uid == selectedStudyUid) {
                    entry = state.worklist[j]
                    break
                }
            }
            if (entry == null) {
                // Check if there is a secondary study entry available...
                //
                for(let j=0; j < state.secondaryWorklist.length; j++) {
                    if (state.secondaryWorklist[j].study_uid == selectedStudyUid) {
                        entry = state.secondaryWorklist[j]
                        break
                    }
                }
            }
            return entry;
        },
        mgmtReportEntryForStudy: (state) => (studyUid, morphToWorklistEntry) => {
            var entry = null
            for(var j=0; j < state.mgmtReportItems.length; j++)
            {
                if (state.mgmtReportItems[j]._study_iuid == studyUid) {
                    // Create minimal worklist type entry from management report entry
                    //
                    if (morphToWorklistEntry) {
                        const rptEntry = state.mgmtReportItems[j]
                        entry = {
                            study_uid: rptEntry._study_iuid,
                            study_date: rptEntry._study_date,
                            group: rptEntry['Clinic / Tenant Name'],
                            patient_id: rptEntry['Patient ID'],
                            patient_name: rptEntry['Patient Name'],
                            stat: rptEntry._s
                        }
                    }
                    else {
                        entry = state.mgmtReportItems[j]
                    }
                    break
                }
            }
            return entry;
        },
        nextStudyUid: (state) => (studyUid) => {
            var study_uid = state._nextStudyUid
            const userId = state.keycloak.tokenParsed.sub
            const filtered =  state.worklistSorted.filter(item => {
                let include = item.can_view_images
                if (include && permissions.hasPermission(item.group, permissions.CAN_LOCK_STUDY)) {
                  include = ((item.lock == '') || (item.lock == userId))
                }
                return include
            })
            for(var j=1; j < filtered.length; j++) {
                if (filtered[j].study_uid == studyUid) {
                    study_uid = filtered[j - 1].study_uid
                    break
                }
            }
            return study_uid;
        },
        previousStudyUid: (state) => (studyUid) => {
            var study_uid = state._previousStudyUid
            const userId = state.keycloak.tokenParsed.sub
            const filtered =  state.worklistSorted.filter(item => {
                let include = item.can_view_images
                if (include && permissions.hasPermission(item.group, permissions.CAN_LOCK_STUDY)) {
                  include = ((item.lock == '') || (item.lock == userId))
                }
                return include
            })
            for(var j=0; j < filtered.length - 1; j++)
            {
                if (filtered[j].study_uid == studyUid) {
                    study_uid = filtered[j + 1].study_uid
                    break
                }
            }
            return study_uid;
        },
        selectedStudyUid: (state) => (windowUid) => {
            let studyUid = state._selectedStudyUids[windowUid]
            if (studyUid === undefined) {
                studyUid = ''
            }
            return studyUid
        },
        worklistFilter: (state) => (worklistName) => {
            var entry = null
            const keys = Object.keys(state.worklistSettings)
            for(var j=0; j < keys.length; j++) {
                if (worklistName == keys[j]) {
                    entry = state.worklistSettings[worklistName];
                    break;
                }
            }
            return entry;
        },
        currentWorklistFilter: (state) => {
            var entry = null
            const keys = Object.keys(state.worklistSettings)
            for(var j=0; j < keys.length; j++) {
                if (keys[j] == state.worklistName) {
                    entry = state.worklistSettings[keys[j]];
                    break;
                }
            }
            return entry;
        },
        defaultWorklistName: (state) => {
            var worklistName = 'Today'
            const keys = Object.keys(state.worklistSettings)
            for(var j=0; j < keys.length; j++) {
                if (state.worklistSettings[keys[j]].default) {
                    worklistName = keys[j];
                    break;
                }
            }
            Vue.$log.debug("default worklist filter=["+worklistName+"]")
            return worklistName;
        },
        aetForGroup: (state) => (group) => {
            var aet = ''
            try {
                if (Object.keys(state.groupAetLut).includes(group)) {
                    aet = state.groupAetLut[group]
                }
            }
            catch {
                aet = ''
            }
            return aet;
        },
        groups: (state) => {
            return state.keycloak.tokenParsed.groups;
        },
        isReportOpenForEdit: (state) => (windowUid) => {
            var editing = (state.activeComponent == 'ReportEditor')
            editing |= ((state.reportWindowsEditing[windowUid] !== undefined) && state.reportWindowsEditing[windowUid])
            return editing
        },
        isAnyReportOpenForEdit: (state) => {
            var editing = (state.activeComponent == 'ReportEditor')
            const uids = Object.keys(state.reportWindowsEditing)
            for (var k = 0; k < uids.length; k++) {
                let uid = uids[k]
                editing |= state.reportWindowsEditing[uid]
            }
            return editing
        },
        openReportsInWindow: (state) => {
            return (state.worklistViewers.reports_open_in == "window")
        },
        openViewersInWindow: (state) => {
            return (state.worklistViewers.viewers_open_in == "viewerWindow")
        }
    },
    mutations: {
        addSecondaryWorklistEntry(state, payload) {
            const filtered =  state.secondaryWorklist.filter(item => {
                return item.study_uid == payload.study_uid
            })
            if (filtered.length == 0) {
                state.secondaryWorklist.push(payload)
            }
        },
        removeSecondaryWorklistEntry(state, payload) {
            // payload is studyUid
            state.secondaryWorklist = state.secondaryWorklist.filter(item => {
                return item.study_uid != payload
            })
        },
        changeCachedWorklistName(state, payload) {
            state.cachedWorklistName = payload
        },
        changeCheckedStudies(state, payload) {
            state.checkedStudies = payload
        },
        changeDicomWebQidoUrl(state, payload) {
            state.dicomWebQidoUrl = payload
        },
        changeGroupAetLut(state, payload) {
            state.groupAetLut = payload
        },
        changeKeycloak(state, payload) {
            state.keycloak = payload
        },
        changeLocale(state, payload) {
            state.locale = payload
        },
        changePatientHistoryDocId(state, payload) {
            state.patientHistoryDocId = payload
        },
        changeReportId(state, payload) {
            state.reportId = payload
        },
        changeActiveComponent(state, payload) {
            state.activeComponent = payload
        },
        changeActiveStudyUid(state, payload) {
            state.activeStudyUid = payload
        },
        changeNextStudyUid(state, payload) {
            state._nextStudyUid = payload
        },
        changePreviousStudyUid(state, payload) {
            state._previousStudyUid = payload
        },
        changeReportPhraseLut(state, payload) {
            state.reportPhraseLut = payload
        },
        changeSelectedStudyUid(state, payload) {
            state._selectedStudyUid = payload
            state._selectedStudyUids[state.uid] = payload
        },
        changeSelectedStudyUids(state, payload) {
            if (typeof payload === 'string') {
                state._selectedStudyUids[state.uid] = payload
            }
            else {
                let uid = (payload.windowUid === undefined) ? state.uid : payload.windowUid
                state._selectedStudyUids[uid] = payload.studyUid
            }
        },
        changeUserProfile(state, payload) {
            state.userProfile = payload
        },
        changeDocServicesUrl(state, payload) {
            state.docServicesUrl = payload
        },
        changeUploadLimitMb(state, payload) {
            state.uploadLimitMb = payload
        },
        changeMgmtReportItems(state, payload) {
            state.mgmtReportItems = payload
        },
        changeMgmtReportRefreshing(state, payload) {
            state.mgmtReportRefreshing = payload
        },
        changeMgmtReportTS(state, payload) {
            state.mgmtReportTS = payload
        },
        changeMgmtReportType(state, payload) {
            state.mgmtReportType = payload
        },
        changePrevRoute(state, payload) {
            state.prevRoute = payload
        },
        changeQueryLimit(state, payload) {
            state.queryLimit = payload
        },
        changeRefreshViewer(state, payload) {
            state.refreshViewer = payload
        },
        changeReportWindowsEditing(state, payload) {
            state.reportWindowsEditing[payload.windowUid] = payload.editing
        },
        changeReportWindows(state, payload) {
            if (payload.window !== null) {
                state.reportWindows[payload.windowUid] = payload.window
            }
            else {
                delete state.reportWindows[payload.windowUid]
                delete state.reportWindowsEditing[payload.windowUid]
            }
        },
        changeViewerWindowRefresh(state, payload) {
            state.viewerWindowRefresh = payload
        },
        changeViewerWindowsRoute(state, payload) {
            state.viewerWindowsRoute[payload.windowUid] = payload.route
        },
        changeViewerWindows(state, payload) {
            if (payload.window !== null) {
                state.viewerWindows[payload.windowUid] = payload.window
            }
            else {
                delete state.viewerWindows[payload.windowUid]
                delete state.viewerWindowsRoute[payload.windowUid]
            }
        },
        changeWebServicesBaseUrl(state, payload) {
            state.webServicesBaseUrl = payload
        },
        changeWorklist(state, payload) {
            state.worklist = payload
            state.worklistRefreshTime = new Date().toLocaleString(state.locale)
        },
        changeWorklistCount(state, payload) {
            state.worklistCount = payload
        },
        changeWorklistRefreshFlag(state, payload) {
            state.worklistRefreshFlag = payload
        },
        changeWorklistRefreshing(state, payload) {
            state.worklistRefreshing = payload
        },
        changeWorklistRefreshInterval(state, payload) {
            state.worklistRefreshInterval = payload
        },
        changeWorklistSorted(state, payload) {
            state.worklistSorted = payload
        },
        changeWorklistName(state, payload) {
            state.worklistName = payload
        },
        changeWorklistFilter(state, payload) {
            state.worklistSettings[payload.worklistName] = payload
            if (payload.default) {
                const keys = Object.keys(state.worklistSettings)
                for(var j=0; j < keys.length; j++) {
                    if (payload.worklistName != keys[j]) {
                        state.worklistSettings[keys[j]].default = false;
                    }
                }
            }
            state.worklistNames = Object.keys(state.worklistSettings).sort()
        },
        changeWorklistSettings(state, payload) {
            state.worklistSettings = payload
            state.worklistNames = Object.keys(state.worklistSettings).sort()
        },
        changeWorklistActions(state, payload) {
            state.worklistActions = payload
        },
        changeWorklistColumns(state, payload) {
            state.worklistColumns = payload
        },
        changeWorklistColumnWidths(state, payload) {
            state.worklistColumnWidths = payload
        },
        changeWorklistPerPage(state, payload) {
            state.worklistPerPage = payload
        },
        changeWorklistAltViewers(state, payload) {
            state.worklistAltViewers = payload
        },
        changeWorklistViewers(state, payload) {
            state.worklistViewers = payload
        },
        changeWwwlFavorites(state, payload) {
            state.wwwlFavorites = payload
        },
        removeWorklistFilter(state, worklistName) {
            delete state.worklistSettings[worklistName]
            state.worklistNames = Object.keys(state.worklistSettings).sort()
        }
    },
    actions: {
    }
});