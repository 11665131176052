// main.js - initialize application
//
// Bootstrap Vue
//
import Vue from 'vue'
import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons }  from 'bootstrap-vue'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Logging
//
import VueLogger from 'vuejs-logger'

// Authentication
//
import Keycloak from 'keycloak-js'

// Vuex
//
import store from './store'

// Routes for Vue Router
//
import routes from './routes'

// PACS app
//
import App from './App.vue'
import webServices from './common/webServices'

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Landing page configs
//
const LANDING_PAGE_URL = "/landing/index.html"

// Set up logging
//
const isProduction = process.env.NODE_ENV === 'production';
const options = {
  isEnabled: true,
  logLevel : isProduction ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};
Vue.use(VueLogger, options);

function displayLoginError() {
  document.getElementById("app").innerHTML += '<p style="color: white;">Error connecting to server.</p><p style="color: white;"><a href="javascript:window.location.reload()">RETRY</a></p>'
}

axios.get(process.env.BASE_URL+"configs.json")
.then(response => {
  var configs = response.data
  Vue.$log.info("Loaded configs.json");
  Vue.prototype.$configs = configs

  store.commit("changeDocServicesUrl", configs.docServicesUrl);
  store.commit("changeDicomWebQidoUrl", configs.dicomWebQidoUrl);
  store.commit("changeWebServicesBaseUrl", configs.webServicesBaseUrl);
  store.commit("changeUploadLimitMb", configs.uploadLimitMb)
  store.commit("changeQueryLimit", configs.queryLimit)

  // Set up router
  //
  const router = new VueRouter({routes})

  // Handle authentication steps.
  //
  if (configs.authUrl != '')
  {
    var onLoad = "login-required"
    if (configs.useLandingPage && (window.location.search != "?login")) {
      onLoad = "check-sso"
    }
    var keycloakOptions = {
      "url": configs.authUrl,
      "realm": configs.authRealm,
      "clientId": configs.authClientId,
      "onLoad": onLoad,
      "checkLoginIframe": true
    }
    let keycloak = Keycloak(keycloakOptions);
    Vue.$log.debug('keycloak init starting')
    keycloak.init({ onLoad: keycloakOptions.onLoad })
    .then((auth) => {
      Vue.$log.debug('keycloak init done')
      if (!auth) {
        window.location.reload();
        window.location = LANDING_PAGE_URL;
      } else {
        Vue.$log.info("Authenticated");
        store.commit("changeKeycloak", keycloak)
      }
      Vue.$log.debug(keycloak.tokenParsed)
      var profile = { 
        username: keycloak.tokenParsed.preferred_username,
        firstName: keycloak.tokenParsed.given_name,
        lastName: keycloak.tokenParsed.family_name
      }
      store.commit("changeUserProfile", profile);

      // Create the Group to AE title LUT
      //
      var groupAetLut = {}
      for (var g=0; g < keycloak.tokenParsed.groups.length; g++) {
        const group = keycloak.tokenParsed.groups[g]
        Vue.$log.info("Reading AE title for group=["+group+"]")
        webServices.readGroupAeTitle(group)
        .then(aet => {
          groupAetLut[group] = aet;
          store.commit("changeGroupAetLut", groupAetLut);
        })
        .catch(err => {
            Vue.$log.error("Error reading AE title for group=["+group+"]: "+err.message)
        })
      }

      // Launch app
      //
      Vue.$log.debug("Starting app...")
      new Vue({
        el: '#app',
        router,
        store,
        render: h => h(App)
      })

      // Token Refresh
      //
      // +TODO+ need to not refresh if client should be auto-logged out.
      //
      setInterval(() => {
        keycloak.updateToken(70).then((refreshed) => {
          Vue.$log.info('Token refreshed: ' + refreshed);
          if (refreshed) {
            // Refresh the Group to AE title LUT
            //
            var groupAetLut = {}
            for (var g=0; g < keycloak.tokenParsed.groups.length; g++) {
              const group = keycloak.tokenParsed.groups[g]
              Vue.$log.info("Reading AE title for group=["+group+"]")
              webServices.readGroupAeTitle(group)
              .then(aet => {
                groupAetLut[group] = aet;
                store.commit("changeGroupAetLut", groupAetLut);
              })
              .catch(err => {
                  Vue.$log.error("Error reading AE title for group=["+group+"]: "+err.message)
              })
            }
          } else {
          //  Vue.$log.warn('Token not refreshed, valid for '
          //    + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
          }
        }).catch(() => {
          // Handle session timeout or other token errors
          //
          Vue.$log.error('Failed to refresh token');
        });
      }, 6000)
    })
    .catch(() => {
      Vue.$log.error("Authentication Failed");
      displayLoginError()
    });
  }
  else
  {
    Vue.$log.warn("Authentication not configured");
    displayLoginError()
  }
})
.catch((error) => {
  Vue.$log.error("Error getting configs.json: "+error.message);
  displayLoginError()
});