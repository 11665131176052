
const routes = [
    { path: '/', component: () => import('./components/Worklist.vue') },
    { path: '/logout', component: () => import('./components/Logout.vue') },
    { path: '/mgmt_reports', component: () => import('./components/MgmtReports.vue') },
    { path: '/report', component: () => import('./components/ReportWindow.vue') },
    { path: '/viewer', component: () => import('./components/Viewer.vue') },
    { path: '/viewer_lite', component: () => import('./components/ViewerLite.vue') },
    { path: '/viewer_local', component: () => import('./components/ViewerLocal.vue') },
    { path: '/worklist', component: () => import('./components/Worklist.vue') },
];

export default routes;